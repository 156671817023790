import firebase from "firebase";

// See firebase setup in above google firebase documentation url
export const config = {
    apiKey: "AIzaSyBU546hr7UyEv9U_5bUui7mVgZnRw3wl58",
    authDomain: "shipdrip-jitsu.firebaseapp.com",
    databaseURL: "https://shipdrip-jitsu.firebaseio.com",
    projectId: "shipdrip-jitsu",
    storageBucket: "shipdrip-jitsu.appspot.com",
    messagingSenderId: "663211712232",
    appId: "1:663211712232:web:468add4d1927c90822735d"
};

firebase.initializeApp(config);
export default firebase;