import React from 'react';
import LimitSelect, { pageLimitOptions } from './LimitSelect';
export const DocPagination = (props) => {
    const { docsPageLmit, docsPage, docFilter } = props
    return (
        <div className="w-100 page-search-container">
            <div className="d-flex flex-nowrap page-search-width align-items-center">
                <LimitSelect selected={docsPageLmit} onSelect={(event) => {
                    props.setdocsPageLmit(event.label);
                    props.setdocsPage(1);
                }} options={pageLimitOptions} />
                <nav>
                    <ul className="pagination m-0">
                        <li className="page-item">
                            <a onClick={() => {
                                if (docsPage > 1) {
                                    props.setdocsPage(docsPage > 1 ? docsPage - 1 : docsPage);
                                }
                            }} className="btn page-link" aria-disabled="true">Previous</a>
                        </li>
                        <li className="page-item"><a className="btn page-link">{docsPage}</a></li>
                        <li className="page-item">
                            <a
                                onClick={() => {
                                    props.setdocsPage(docsPage + 1);
                                }}
                                className="btn page-link">
                                Next
                                </a>
                        </li>
                    </ul>
                </nav>
                <input
                    className="form-control flex-1"
                    value={docFilter}
                    placeholder="filter docs"
                    onChange={(event) => { props.setDocFilter(event.target.value); }}
                    type="text"
                />
            </div>
        </div>
    );
};

export const ShelfsPagination = (props) => {
    const { shelfPageLmit, shelfPage, shelfFilter } = props
    return (
        <div className="w-100 page-search-container">
            <div className="d-flex flex-nowrap page-search-width align-items-center" >
                <LimitSelect
                    selected={shelfPageLmit}
                    onSelect={(event) => {
                        props.setshelfPageLmit(event.label);
                        props.setshelfPage(1);
                    }}
                    options={pageLimitOptions}
                />
                <nav>
                    <ul className="pagination m-0">
                        <li className="page-item">
                            <a onClick={() => {
                                if (shelfPage > 1) {
                                    props.setshelfPage(shelfPage > 1 ? shelfPage - 1 : shelfPage);
                                }
                            }}
                                className="btn page-link" aria-disabled="true">Previous</a>
                        </li>
                        <li className="page-item"><a className="btn page-link" >{shelfPage}</a></li>
                        <li className="page-item">
                            <a onClick={() => {
                               props.setshelfPage(shelfPage + 1);

                            }}
                                className="btn page-link" >Next</a>
                        </li>
                    </ul>
                </nav>
                <input
                    className="form-control flex-1"
                    value={shelfFilter}
                    placeholder="filter shelves"
                    onChange={(event) => {  props.setshelfFilter(event.target.value) }}
                    type="text"
                />
            </div>
        </div>
    );
};






