import axios from 'axios';

const apiKey = "AIzaSyB68Fo-GSHVpEGKriwKkIFKtpQqm0YZ4j4";
const startAts = {};



const setStartAtString = ({ page, limit }, startAt) => {
    const key = `${page}-${limit}`;
    return startAts[key] = startAt;
};

const getStartAtString = ({ page, limit, }) => {
    const key = `${page}-${limit}`;
    return startAts[key];
};

export const getDriveFolders = async (props) => {

    const { token, pageToken, pageSize = 10, branchKey } = props;

    const headers =  props.headers || (branchKey ? {} : { "Authorization": `Bearer ${token}` });
    const key = branchKey ? apiKey : undefined;

    const q = props.q || (branchKey ? `mimeType = 'application/vnd.google-apps.folder' and trashed = false and '${branchKey}' in parents` : `mimeType = 'application/vnd.google-apps.folder' and trashed = false`)

    try {
        const response = await axios({
            method: 'get',
            url: `https://www.googleapis.com/drive/v3/files`,
            params: {
                q,
                fields: 'nextPageToken, files(id, name, mimeType, parents, description, thumbnailLink, webViewLink, properties, fileExtension, exportLinks, size, hasThumbnail, capabilities)',
                pageToken,
                pageSize,
                orderBy:'name',
                key,
            },
            headers: {
                ...headers,
            }
        });

        const { data } = response;

        const { nextPageToken, files } = data;

        const converted = files

            .map(folder => {
                return {
                    ...folder,
                    properties: {},
                }
            })

            .map(folder => {
                return {
                    ...folder,
                    title: folder.name,
                    q: folder.id,
                    active: true,
                    defaultOpen: true,
                }
            })

        return {
            nextPageToken,
            folders: [
                ...converted
            ],
        };

    } catch (error) {
        console.log("getDriveFolders -> error", error);
    }
}

export const fetchShelfs = async (props) => {
    const { limit = 25, page = 1, branchKey,user } = props;
    const token = user? user.token : undefined
    const pageToken = getStartAtString({ page, limit });

    if (pageToken) {
        const results = await getDriveFolders({
            token,
            pageToken,
            pageSize: limit,
            branchKey,
        });
        const { nextPageToken, folders } = results || {};
        setStartAtString({ page: page + 1, limit }, nextPageToken);

        return { shelfs: folders }
    } else {
        const results = await getDriveFolders({
            token,
            pageSize: limit,
            branchKey,
        });
        const { nextPageToken, folders } = results || {};
        setStartAtString({ page: page + 1, limit }, nextPageToken);
        return { shelfs: folders }


    }
}

export const deleteFile = async (props) => {

    const { token, fileId } = props;

    const headers = { "Authorization": `Bearer ${token}` };

    try {
        const response = await axios({
            method: 'delete',
            url: `https://www.googleapis.com/drive/v3/files/${fileId}`,
       
            headers: {
                ...headers,
            }
        });

    } catch (error) {
        console.log("getDriveFolderFiles -> error", error);
    }
}

export const getDriveFolderFiles = async (props) => {

    const { token, pageToken, pageSize = 10, folderId, branchKey } = props;

    const headers = branchKey ? {} : { "Authorization": `Bearer ${token}` };
    const key = branchKey ? apiKey : undefined;

    try {
        const response = await axios({
            method: 'get',
            url: `https://www.googleapis.com/drive/v3/files`,
            params: {
                q: `mimeType != 'application/vnd.google-apps.folder' and trashed = false and '${folderId}' in parents`,
                fields: 'nextPageToken, files(id, name, mimeType, parents, description, thumbnailLink, webViewLink, properties, fileExtension, exportLinks, size, hasThumbnail, capabilities)',
                pageToken,
                pageSize,
                key,
            },
            headers: {
                ...headers,
            }
        });

        const { data } = response;

        const { nextPageToken, files } = data;

        const converted = files

            .map(file => {
                return {
                    ...file,
                    properties: {},
                }
            })

            .map(file => {
                return {
                    ...file,
                    title: file.name,
                    q: file.id,
                    imageUrl: file.thumbnailLink,
                    pdfUrl: file.webViewLink
                }
            })

        return {
            nextPageToken,
            docs: [
                ...converted
            ],
        };

    } catch (error) {
        console.log("getDriveFolderFiles -> error", error);
    }
}

const startAtsDocs = {}

const setStartAtStringDocs = ({ page, q, limit }, startAt) => {
    const key = `${page}-${q}-${limit}`;
    return startAtsDocs[key] = startAt;
};

const getStartAtStringDocs = ({ page, q, limit, }) => {
    //console.log("getStartAtStringDocs -> page, q, limit,", page, q, limit,)
    const key = `${page}-${q}-${limit}`;
    return startAtsDocs[key]
};

export const fetchDocs = async (props) => {
    const { limit = 25, page = 1, q, branchKey,user } = props;
    const token = user? user.token : undefined
    const pageToken = getStartAtStringDocs({ page, q, limit });
    if (pageToken) {
        const results = await getDriveFolderFiles({
            token,
            folderId: q,
            pageToken,
            pageSize: limit,
            branchKey,
        });
        const { nextPageToken, docs } = results || {};
        setStartAtStringDocs({ q, page: page + 1, limit }, nextPageToken);

        return { docs }
    } else {
        const results = await getDriveFolderFiles({
            token,
            folderId: q,
            pageSize: limit,
            branchKey,
        });
        const { nextPageToken, docs } = results || {};
        setStartAtStringDocs({ q, page: page + 1, limit }, nextPageToken);
        return { docs }
    }
}

