import React, { useState } from 'react';
export const pageLimitOptions = [
  {
    label: 10
  },
  {
    label: 25
  },
  {
    label: 50
  },
  {
    label: 100
  }
];
const LimitSelect = (props) => {
  const { options = [], selected } = props;
  const [gate, setGate] = useState(!!selected);
  return (<nav>
    <ul className="pagination m-0 page-limit">
      {options
        .filter(option => (gate && selected) ? option.label == selected : true)
        .map(option => (<li
          key={option.label}
          className="page-item">
          <button

            onClick={() => {
              if (selected && gate) {
                setGate(false);
              }
              else {
                props.onSelect(option);
                setGate(true);
              }
            }} className="btn page-link">
            {option.label}
          </button>
        </li>))}
    </ul>
  </nav>);
};


export default LimitSelect;