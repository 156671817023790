
import firebase from './firebase';
import { getDriveFolders } from './gdriveAdapter';
import loadScript from 'load-script';
import { useState, useEffect } from 'react';

const apiKey = "AIzaSyB68Fo-GSHVpEGKriwKkIFKtpQqm0YZ4j4";

async function googleAsync(props) {

    await new Promise((resolve, reject) => loadScript('https://apis.google.com/js/api.js', {}, resolve));

    if (!window.gapi.client) {
        await new Promise((resolve, reject) => window.gapi.load('client:auth2', {
            callback: resolve,
            onerror: reject
        }));
    }

    await window.gapi.client.init({
        'apiKey': apiKey,
        'clientId': '663211712232-mcdlnmh5j3l8tkrakkbgb1b3jqio6qal.apps.googleusercontent.com',
        'scope': 'https://www.googleapis.com/auth/drive.file',
    })

    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    props.setAuth(GoogleAuth)
    // Listen for sign-in state changes.
    GoogleAuth.isSignedIn.listen(async (isSignedIn) => {
        console.log("googleAsync -> isSignedIn", isSignedIn)
        var user = GoogleAuth.currentUser.get();

        if (isSignedIn) {
            const profile = user.getBasicProfile()
            const auth = user.getAuthResponse(true)
            const { access_token } = auth
            const email = profile.getEmail()
            const isAdmin = (email === "isethguy@gmail.com" || email === "00sethen@gmail.com");
    
            const response = await getDriveFolders({
                branchKey: props.branchKey,
                headers: { "Authorization": `Bearer ${access_token}` },
                q: `mimeType = 'application/vnd.google-apps.folder' and trashed = false and name = 'jitsulib'`
            })
    
            const [jitsulib] = response.folders.filter((folders) => folders.name == "jitsulib")
    
            if (jitsulib) {
                props.setBranchKey(jitsulib.id)
            }
            props.setUser({ email, token: access_token, isAdmin: true, GoogleAuth })
        } else {
            props.setUser(undefined)
            props.setBranchKey(undefined)

        }
    });

    var user = GoogleAuth.currentUser.get();

    if (user.isSignedIn()) {
        const profile = user.getBasicProfile()
        const auth = user.getAuthResponse(true)
        const { access_token } = auth
        const email = profile.getEmail()
        const isAdmin = (email === "isethguy@gmail.com" || email === "00sethen@gmail.com");

        const response = await getDriveFolders({
            branchKey: props.branchKey,
            headers: { "Authorization": `Bearer ${access_token}` },
            q: `mimeType = 'application/vnd.google-apps.folder' and trashed = false and name = 'jitsulib'`
        })

        const [jitsulib] = response.folders.filter((folders) => folders.name == "jitsulib")

        if (jitsulib) {
            props.setBranchKey(jitsulib.id)
        }
        props.setUser({ email, token: access_token, isAdmin: true, GoogleAuth })

    }

}

export function useInitWatchAuth(props) {
    useEffect(() => {
        googleAsync(props)
    }, []);

}
