import { useState, useEffect } from 'react';
import axios from 'axios'
import moment from 'moment';

import { useShelfsEffect, removeShelf, fetchShelfs } from './shelfActions';
import firebase from './firebase'

import {useInitWatchAuth} from './useInitWatchAuth';

const defaultBranch =  "1UoAQRfQJGBvMlitir_ZaqIy4zUVUGL_n"

export function useInitStore(props) {
  const [menuOpen, setMenuOpen] = useState(true);
  const [collapseShelfs, setcollapseShelfs] = useState(false);
  const [shelfs, setShelfs] = useState([]);
  const [selectedShelfshelf, setSelectedShelf] = useState(undefined);
  const [docsPageLmit, setdocsPageLmit] = useState(50);
  const [shelfPageLmit, setshelfPageLmit] = useState(10);
  const [docsPage, setdocsPage] = useState(1);
  const [shelfPage, setshelfPage] = useState(1);
  const [shelfFilter, setshelfFilter] = useState("");
  const [docFilter, setDocFilter] = useState("");
  const [user, setUser] = useState(undefined);
  const [auth, setAuth] = useState(undefined);
  const [systemCollapseOpen, setSystemCollapseOpen] = useState(false);

  const [branchKey, setBranchKey] = useState(defaultBranch);

  const state = {
    menuOpen,
    collapseShelfs,
    shelfs,
    selectedShelfshelf,
    docsPageLmit,
    shelfPageLmit,
    docsPage,
    shelfPage,
    shelfFilter,
    docFilter,
    user,
    auth,
    systemCollapseOpen,
    branchKey:  branchKey || defaultBranch
  };
  const actions = {
    setMenuOpen,
    setcollapseShelfs,
    setShelfs,
    setSelectedShelf,
    setdocsPageLmit,
    setshelfPageLmit,
    setdocsPage,
    setshelfPage,
    setshelfFilter,
    setDocFilter,
    setUser,
    removeShelf,
    setBranchKey,
    setAuth,
    setSystemCollapseOpen,
  };

  useInitWatchAuth({
    setUser,
    setBranchKey,
    setAuth,
    branchKey,
  })

  useShelfsEffect({
    setShelfs,
    branchKey:  branchKey || defaultBranch,
    setMenuOpen,
    setSelectedShelf,
    page: shelfPage,
    limit: shelfPageLmit
  });

  useEffect(() => {
    window.onhashchange = function () {
      if (!window.location.hash) {
        setMenuOpen(true)
      } else {
        setMenuOpen(false)
      }
    }
  }, []);

  return { actions, state };
}
