import axios from 'axios';
import firebase from './firebase';

const startAts = {};

const setStartAtString = ({ page, limit }, startAt) => {
    const key = `${page}-${limit}`;
    return startAts[key] = startAt;
};

const getStartAtString = ({ page, limit, }) => {
    const key = `${page}-${limit}`;
    return startAts[key];
};

export const fetchShelfs = async (props) => {

    try {

        const { limit = 25, page = 1 } = props;
        const startAt = (page - 1) * limit;

        const snap = await firebase.firestore()
            .collection('shelfs')
            .where('active', '==', true)
            .limit(limit)
            .orderBy('sort', 'asc')
            .startAt(startAt).get();

        const shelfs = snap.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        }));

        return { shelfs }

    } catch (error) {
        console.log("fetchShelfs -> error", error)

    }

}



const startAtsDocs = {};

const setStartAtStringDocs = ({ page, q, limit }, startAt) => {
    const key = `${page}-${q}-${limit}`;
    return startAtsDocs[key] = startAt;
};

const getStartAtStringDocs = ({ page, q, limit, }, startAt) => {
    const key = `${page}-${q}-${limit}`;
    return startAtsDocs[key] || setStartAtStringDocs({ page, q, limit }, startAt);
};
export const fetchDocs = async (props) => {
    const { items = [], q, limit = 10, page = 1 } = props;

    const ref = firebase.firestore().collection('pdfdata');
    const whereQuery = ref.where('q', '==', q);
    const startAt = (page - 1) * limit;
    const query = startAt ?
        whereQuery.limit(limit).orderBy('href', 'asc')
            .startAt(getStartAtStringDocs({ page, q, limit }, items[items.length - 1].href)) :
        whereQuery.limit(limit).orderBy('href', 'asc');
    const snap = await query.get();
    const docs = snap.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }));

    return { docs }

}

