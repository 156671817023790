import React from 'react';
import AddShelfForm from './AddShelfForm';

const SystemCollapse = (props) => {
    const { user } = props
    return (
        <div
            className="collapse"
            id="collapseExample">
            <div
                className="card card-body secrect-wammy">
                                        <AddShelfForm  {...{ ...props }} />

                <button
                    onClick={() => {
                        if (user) {
                            props.setUser(undefined);
                            user.GoogleAuth.signOut();
                        }
                        else {
                            props.auth.signIn()
                        }
                    }}
                    className="btn ship-auth-btn">
                    {`${user ? 'Sign Out' : 'Sign In'}`}
                </button>
            </div>
        </div>
    );
};

export default SystemCollapse;
