import React, { useState } from 'react';
import { ShelfCollapseSummary } from './ShelfCollapseSummary';

const TextOrButton = (props) => (props.onClick ? <button {...{ ...props }} /> : <p {...{ ...props }} />)

export const ShelfCollapse = (props) => {
  const { shelf, defaultOpen, user, defaultHeightClass, docFilter,page ,branchKey} = props;
  const { q, title } = shelf;
  const collapseId = `shelf-${q}`;
  const open = defaultOpen;
  const [items, setItems] = useState([]);
  const [heightClass, setHeightClass] = useState(defaultHeightClass || 'h-100');
  return (
    <div className={`my-1 ${open ? heightClass : ''} d-flex flex-column`}>
      <div className="d-flex flex-nowrap align-items-center my-2 ship-shelf-title-container">
        <TextOrButton
          className="btn ship-nav-title"
          style={{
            padding: "5px 10px",
          }}
          onClick={props.onClick}
          data-toggle="collapse"
          aria-expanded="false"
          aria-controls={`${collapseId}`}>
          {title}
        </TextOrButton>
        {
          props.setDocFilter && user && user.isAdmin &&
          <div className="ml-2">
            <i
              onClick={ (event) => {
                  props.removeShelf(shelf)
              }}
              className="fa btn fa-trash" />
          </div>
        } 
      </div>
      {
        open && <ShelfCollapseSummary
          docFilter={props.docFilter}
          page={page}
          branchKey={branchKey}
          items={items}
          limit={props.limit}
          onClick={props.onClick}
          setItems={setItems}
          open={open}
          user={user}
          shelf={shelf} />
      }
    </div>
  );
};
