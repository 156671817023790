import { useEffect } from 'react';
import firebase from './firebase';
import * as driveAdapter from './gdriveAdapter';
import * as fireAdapter from './fireAdapter';

export const fetchShelfs = async (props) => {

    if (props.branchKey) {
        const driveShelfResults = await driveAdapter.fetchShelfs(props);
        const { shelfs } = driveShelfResults
        if (window.location.hash) {
            const title = decodeURIComponent(window.location.hash.replace('#/', ''));
            const [found] = shelfs.filter((shelf) => shelf.title === title);
            if (found && found.q) {
                props.setMenuOpen(false);
                props.setSelectedShelf(found);
            }else{
                window.location.hash =""
            }
        }
        props.setShelfs(shelfs);
    } else {
        const { shelfs = [] } = await fireAdapter.fetchShelfs(props);
        if (window.location.hash) {
            const title = decodeURIComponent(window.location.hash.replace('#/', ''));
            const [found] = shelfs.filter((shelf) => shelf.title === title);
            if (found && found.q) {
                props.setMenuOpen(false);
                props.setSelectedShelf(found);
            }else{
                window.location.hash =""
            }
        }
        props.setShelfs(shelfs);
    }
};

export const removeShelf = async (props) => {
    const { shelf, setShelfs, setMenuOpen, setSelectedShelf, page, limit, branchKey } = props;
    const ref = await firebase.firestore().collection('shelfs');
    await ref.doc(shelf.id).delete();
    window.location.hash = "";
    fetchShelfs({ setShelfs, setMenuOpen, setSelectedShelf, page, branchKey, limit });
};

export function useShelfsEffect(props) {
    const { setShelfs, setMenuOpen, setSelectedShelf, page, limit, branchKey } = props;
    return useEffect(() => {
        fetchShelfs({ setShelfs, setMenuOpen, setSelectedShelf, page, branchKey, limit });
    }, [page, limit,branchKey]);
}
