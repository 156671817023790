import React from 'react';
import './App.css';
import { ShelfCollapse } from './ShelfCollapse';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { DocPagination, ShelfsPagination } from './Pagination'
import SystemCollapse from './SystemCollapse';
import { useInitStore } from './useInitStore';

function MainConnecComponent(props) {
  const { actions, state } = useInitStore({});
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <App state={state} actions={actions} />
    </AlertProvider>
  );

}

function App(props) {
  const {
    setMenuOpen,
    setcollapseShelfs,
    setShelfs,
    setSelectedShelf,
    setdocsPageLmit,
    setshelfPageLmit,
    setdocsPage,
    setshelfPage,
    setshelfFilter,
    setDocFilter,
    setUser,
    removeShelf,
    setBranchKey,
    setAuth,
    setSystemCollapseOpen,
  } = props.actions;

  const {
    menuOpen,
    collapseShelfs,
    shelfs,
    selectedShelfshelf,
    docsPageLmit,
    shelfPageLmit,
    docsPage,
    shelfPage,
    shelfFilter,
    docFilter,
    user,
    branchKey,
    auth,
    systemCollapseOpen,
  } = props.state;


  const logo = systemCollapseOpen ? "/blacklogo.png" :"/purplogo.png"

  return (
    <div className="h-100 d-flex flex-column">
      <header
        className={`w-100${systemCollapseOpen ? ' purps' : ''}`}>
        <div className="row mx-1">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <img
              onClick={() => {
                setSystemCollapseOpen(!systemCollapseOpen)
              }}
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded={setSystemCollapseOpen}
              aria-controls="collapseExample"
              src={logo} className="App-logo d-none d-md-block" alt="logo" />
            <img
              onClick={() => {
                setSystemCollapseOpen(!systemCollapseOpen)
              }}
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded={setSystemCollapseOpen}
              aria-controls="collapseExample" src={logo} style={{ height: 50 }} className="App-logo d-md-none" alt="logo" />
            {
              menuOpen ?
                <i
                  onClick={() => {
                    setcollapseShelfs(!collapseShelfs)
                  }}
                  className={`btn px-0 fa fa-toggle-on${collapseShelfs ? ' swtiched' : ''}`}
                  aria-hidden="true" />
                :
                <i
                  onClick={() => {
                    window.location.hash = "";
                  }}
                  className="btn px-0 fa fa-home"
                  aria-hidden="true" />
            }
          </div>
        </div>
      </header>

      <SystemCollapse
        user={user}
        setAuth={setAuth}
        auth={auth}
        setSelectedShelf={setSelectedShelf}
        setdocsPage={setdocsPage}
        setShelfs={setShelfs}
        selectedShelfshelf={selectedShelfshelf}
        setUser={setUser}
        setBranchKey={setBranchKey}
      />

      <div className={`collapse${menuOpen ? '.show h-100 scroll-y' : ''}`} id="shelfMenu">
        <div className="shelfMenu card card-body border-0 p-0 row mx-1">
          <div className="col-md-12 ">
            {
              shelfs.filter(shelf => !shelfFilter ? true : shelf.name.toLowerCase().indexOf(shelfFilter.toLowerCase()) > -1)
                .map((shelf, i) => (
                  <div key={shelf.q}
                    className="w-100 my-1">
                    <ShelfCollapse
                      limit={10}
                      branchKey={branchKey}
                      defaultOpen={collapseShelfs ? false : shelf.defaultOpen}
                      onClick={() => {
                        window.location.href = `#/${shelf.title}`;
                        setSelectedShelf(shelf);
                        setdocsPage(1);
                      }}
                      user={user}
                      shelf={shelf}
                    />
                  </div>
                ))
            }
          </div>
        </div>
      </div>
      {
        menuOpen &&
        <div className="row m-1">
          <div className="col-md-12">
            <ShelfsPagination
              shelfFilter={shelfFilter}
              setshelfPageLmit={setshelfPageLmit}
              shelfPage={shelfPage}
              setdocsPageLmit={setdocsPageLmit}
              setshelfPage={setshelfPage}
              setshelfFilter={setshelfFilter}
              shelfPageLmit={shelfPageLmit}
            />
          </div>
        </div>
      }
      <div className="flex-1 shelf-page-content-container">
        <div className="row h-100 mx-1">
          <div className="col-md-12 h-100">
            {
              !!selectedShelfshelf &&
              <ShelfCollapse
                branchKey={branchKey}
                limit={docsPageLmit}
                removeShelf={(shelf) => {
                  removeShelf({
                    shelf,
                    setShelfs,
                    setMenuOpen,
                    setSelectedShelf,
                    page: shelfPage,
                    limit: shelfPageLmit,
                    branchKey,
                  })
                }}
                page={docsPage}
                docFilter={docFilter}
                defaultOpen={selectedShelfshelf.defaultOpen}
                user={user}
                shelf={selectedShelfshelf}
              />
            }
          </div>
        </div>
      </div>
      {
        !menuOpen &&
        <div className="row m-1">
          <div className="col-md-12">
            <DocPagination
              docFilter={docFilter}
              docsPageLmit={docsPageLmit}
              docsPage={docsPage}
              setdocsPageLmit={setdocsPageLmit}
              setdocsPage={setdocsPage}
              setDocFilter={setDocFilter}
            />
          </div>
        </div>
      }
    </div>
  );
}

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

export default MainConnecComponent;