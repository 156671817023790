import { useEffect } from 'react';
import * as driveAdapter from './gdriveAdapter';
import * as fireAdapter from './fireAdapter';

const fetchDocs = async (props) => {

    const { shelf } = props;
    const { q, blocks } = shelf;

    if (blocks) {
        props.setItems(blocks);
    } else {
        if (props.branchKey) {
            const driveResults = await driveAdapter.fetchDocs(props);
            const { docs = [] } = driveResults
            props.setItems(docs);
        } else {
            const { docs = [] } = await fireAdapter.fetchDocs(props);
            props.setItems(docs);
        }
    }
};

const removeDoc = async (props) => {
    const { shelf, items, setItems, limit, page, doc, user, branchKey } = props;
    const { q } = shelf;

    // const snap = await firebase.firestore().collection('pdfdata').doc(doc.id)
    // await snap.update({
    //     pdfUrl: 'n/a',
    //     q: 'done',
    //     oldq: doc.q,
    // })
    const driveResults = await driveAdapter.deleteFile({
        fileId: doc.id,
        token: user.token,
    });
    fetchDocs({ setItems, q, limit, branchKey, page, shelf, items, user });

};

export function useDocsEffect(props) {
    const { shelf, items, setItems, limit, page, branchKey, user } = props;
    const { q, } = shelf;
    useEffect(() => {
        fetchDocs({ setItems, q, limit, page, branchKey, items, shelf, user });
    }, [limit, page, q]);

    return { removeDoc }
}

export default fetchDocs;
