import React from 'react';
import { useDocsEffect } from './fetchDocs';


const chopName = (props) => {

  const { name, max } = props;

  if (name.length < max) {

    return name;
  }

  return name.substring(name.length - max, name.length)
}

export const ShelfCollapseSummary = (props) => {
  const { shelf, items, user } = props;
  const { q } = shelf;
  const collapseId = `shelf-${q}`;
  const collapseClass = props.open ? '.show' : '';
  const { removeDoc } = useDocsEffect(props);

  return (
    <div className="flex-1 lib-col-sum scroll-y">
      <div className={`collapse${collapseClass}`} id={`${collapseId}`}>
        <div className={`d-flex${props.onClick ? '' : ' flex-wrap'} `}>
          {
            items
              .filter(doc => !props.docFilter ? true : doc.name.toLowerCase().indexOf(props.docFilter.toLowerCase()) > -1)
              .map(doc => {
                return {
                  ...doc,
                  imageUrl: (doc.size != "0") ? doc.imageUrl : undefined,
                  prename: (doc.size != "0") ? doc.prename : doc.description,
                  pdfUrl: (doc.size != "0") ? doc.pdfUrl : doc.description,

                }
              })
              .map((doc, i) => (
                <div key={i} className={`${doc.selected ? 'selected-doc' : ''}${doc.imageUrl ? '' : ' waiting'}`} >

                  {
                    !!doc.imageUrl ?
                      <img style={{ objectFit: 'contain', height: 300, width: 300 }} src={doc.imageUrl} /> :
                      <div className='border img-place-hold' style={{ height: 300, width: 300 }} >
                        <p className="img-place-hold">loading...</p>
                        {
                          !!doc.prename &&
                          <a className="img-place-hold" target="_blank" href={doc.pdfUrl} >
                            {doc.prename}
                          </a >
                        }
                      </div>

                  }

                  <div className="d-flex justify-content-around align-items-center">
                    {
                      !doc.prename &&
                      <a className="btn fa fa-external-link" target="_blank" href={doc.pdfUrl} />
                    }

                    {
                      user && user.isAdmin && !!doc.id &&
                      < i
                        onClick={async () => {
                          removeDoc({ ...props, doc })
                        }}

                        className="btn fa fa-trash" />
                    }
                  </div>
                  <hr className="w-100 m-0 mt-1" />
                </div>
              ))
          }
        </div>
      </div>
    </div >
  );
};
