import React from 'react';
import firebase from './firebase';
import { useAlert } from 'react-alert'
import axios from 'axios';
const wait = (ms) => new Promise(res => setTimeout(res, ms));

const queryString = require('query-string');

//const createShelfUrl = "http://localhost:8080";
const createShelfUrl = "https://us-central1-shipdrip-jitsu.cloudfunctions.net/driveShip";

const findDocs = (props) => {
    const { shelfdata, alert } = props
    if (shelfdata.search.indexOf('filetype:') > -1) {
        const url = `https://www.google.com/search?q=${shelfdata.search}&start=${0}&num=${100}`;
        window.open(url);
        return;
    }
    const url = `https://www.google.com/search?q=${shelfdata.search} filetype:pdf&start=${0}&num=${100}`;
    window.open(url);
}

const createShelf = async (props) => {
    const { shelfdata, alert } = props

    const response = await axios({
        method: 'post',
        url: createShelfUrl,
        json: true,
        data: shelfdata
    });

    const { data } = response;

    alert.show('found docs!');
    alert.show('uploading to drive');

    const { driveShips } = data;
    console.log("createShelf -> driveShips", driveShips)

    if (driveShips) {

        window.location.href = `#/${shelfdata.title || shelfdata.search}`;

        const progressShelf = {
            title: shelfdata.title || shelfdata.search,
            blocks: driveShips.map(link => {

                const parts = link.href.split('?');
                const [first, params] = parts;

                const [name] = first.split('/').reverse();

                return {
                    pdfUrl: link.href,
                    prename: name
                }
            }),
            defaultOpen: true,
            q: `${shelfdata.title || shelfdata.search}-scrape-zults`
        }
        props.setSelectedShelf(progressShelf);
        props.setdocsPage(1);

    }
};

const showShelfProgress = (props) => {
    const { shelfdata, alert } = props;
    alert.show('searching!');

    window.location.href = `#/${shelfdata.title || shelfdata.search}`;

    const progressShelf = {
        title: shelfdata.title || shelfdata.search,
        blocks: new Array(50).fill({}),
        defaultOpen: true,
        q: `${shelfdata.title || shelfdata.search}-sent`
    }
    props.setSelectedShelf(progressShelf);
    props.setdocsPage(1);

}

const AddShelfForm = (props) => {
    const { user = {} } = props;
    const alert = useAlert()

    return (
        <React.Fragment>
            <form
                className="add-shelf-form"
                onSubmit={(event) => {
                    event.preventDefault();
                    const raw = {};
                    for (let index = 0; index < event.target.elements.length; index++) {
                        const element = event.target.elements[index];
                        if (element.name) {
                            raw[element.name] = element.value;
                        }
                    }
                    if (raw.search) {
                        const shelfdata = {
                            ...raw,
                            token: user.token,
                        };
                        //showShelfProgress({ ...props, alert, shelfdata });
                        //createShelf({ shelfdata, alert, ...props });
                        findDocs({ shelfdata, alert, ...props })
                    }

                }}>
                <input
                    // value={"ches"}
                    placeholder="create query"
                    name="search"
                    type="text"
                    className="form-control input-lg" />
                {/* <input
                    //  value="entrepreneur worksheet"
                    placeholder="title"
                    name="title"
                    type="text"
                    className="form-control input-lg" /> */}
                <div className="row">
                    <div className="col-md-12">
                        <input value="Find Docs" type="submit" className="btn add-self-button" />
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};


export default AddShelfForm